/* Modificando tipos nativos */

interface String {
    interpolate(param: any): String;
}


String.prototype.interpolate = function (params): any {
    const names = Object.keys(params);
    //const values = Object.keys(params).map(key => params[key]);
    const values = (<any>Object).values(params);
    try {
        return new Function(...names, `return \`${this}\`;`)(...values);
    } catch (error) {
        console.log(error);
    }
    
}

