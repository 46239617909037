import { Utils } from "./utils";

export class ColorGenerator {
	private static maxVariationHexa = 16;
	private static numberVariationsRange = 2;

	private notIncludeColorsRGB: number[][];

	constructor(notIncludeColorsHexadecimal: string[]) {
		this.notIncludeColorsRGB = notIncludeColorsHexadecimal.map(color => ColorGenerator.hexToRgb(color));
	}

	// Converter Hexadecimal para RGB
	public static hexToRgb(hex: string) {
		const rgbArrayResult =
			(hex = hex.replace('#', ''))
				.match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
				.map(l => parseInt(hex.length % 2 ? l + l : l, 16));

		return rgbArrayResult;
	}
	// Converter RGB para Hexadecimal
	public static rgbToHex(r, g, b) {
		return '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
	};

	public generateRandomColor() {
		const colorRGB = [
			Utils.getRandomInt(1, 255), 
			Utils.getRandomInt(1, 255), 
			Utils.getRandomInt(1, 255)
		];

		const [colorR, colorG, colorB] = colorRGB;
		const colorHex = ColorGenerator.rgbToHex(colorR, colorG, colorB);

		const colorExistOtherColorRange = (colorRGB: number[]) => {
			let existInRangeColor = false;
			for (let i = 0; i < this.notIncludeColorsRGB.length; i++) {
				const colorNotIncludeRGB = this.notIncludeColorsRGB[i];

				if (this.verifyRangeColorVariation(colorNotIncludeRGB, colorRGB)) {
					existInRangeColor = true;
					break;
				}
			}
			return existInRangeColor;
		}

		// se cor está no range de alguma outra
		if (colorExistOtherColorRange(colorRGB)) {
			// tentar previnir tentativas ao acaso
			let [r, g, b] = colorRGB;

			const getRandomVariation = (param) => {
				// +1 e -1 para sair do range
				const getVariationPositive = (param) => {
					const rangeVariationPositive = this.variationBitRangeColorPositive(param);
					return rangeVariationPositive < 255 ? (rangeVariationPositive + 1) : rangeVariationPositive;
				}
				const getVariationNegative = (param) => {
					const rangeVariationNegative = this.variationBitRangeColorNegative(param);
					return rangeVariationNegative > 0 ? (rangeVariationNegative - 1) : rangeVariationNegative;
				}

				const variant = ColorGenerator.maxVariationHexa * ColorGenerator.numberVariationsRange;
				let variationPositive = getVariationPositive(param);
				let variationNegative = getVariationNegative(param);

				const limitPositive = 255 - variant;
				if(param > limitPositive) {
					return variationNegative;
				} 
				const limitNegative = 0 + variant;
				if (param < limitNegative) {
					return variationPositive;
				}

				const variations = [variationPositive, variationNegative];
				const index = Utils.getRandomInt(0, 1);
				return variations[index];
			};

			const newColor = [getRandomVariation(r), getRandomVariation(g), getRandomVariation(b)];

			if (colorExistOtherColorRange(newColor)) {
				return this.generateRandomColor(); // gerar nova cor
			}

			this.notIncludeColorsRGB.push(colorRGB);
			return colorHex;
		}
		
		this.notIncludeColorsRGB.push(colorRGB);
		return colorHex;
	};

	private variationBitRangeColorPositive(num: number) {
		const result = num + (ColorGenerator.maxVariationHexa * ColorGenerator.numberVariationsRange);
		return result > 255 ? 255 : result;
	};
	private variationBitRangeColorNegative(num: number) {
		const result = num - (ColorGenerator.maxVariationHexa * ColorGenerator.numberVariationsRange);
		return result < 0 ? 0 : result;
	};

	/** Verifica se uma cor está na variação de range de outra cor (analise feita em hexadecimais)
	*
	** color -> cor a ser analisada
	** colorVariation -> cor a variar o range
	*/
	public verifyRangeColorVariation(colorVariationRGB: number[], colorRGB: number[]) {
		const [rV, gV, bV] = colorVariationRGB;
		const [r, g, b] = colorRGB;

		let matchRangeRed: boolean = false;
		let matchRangeGreen: boolean = false;
		let matchRangeBlue: boolean = false;

		if (this.variationBitRangeColorNegative(rV) < r && r < this.variationBitRangeColorPositive(rV)) {
			matchRangeRed = true;
		}
		if (this.variationBitRangeColorNegative(gV) < g && g < this.variationBitRangeColorPositive(gV)) {
			matchRangeGreen = true;
		}
		if (this.variationBitRangeColorNegative(bV) < b && b < this.variationBitRangeColorPositive(bV)) {
			matchRangeBlue = true;
		}

		const matchRangesMatched = [matchRangeRed, matchRangeGreen, matchRangeBlue].filter(range => !!range);
		const matchRangePercentage = (matchRangesMatched.length * 100) / 3;

		return matchRangePercentage > 50; // se pelo menos 2 derem match no range
	}
}
